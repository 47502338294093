import React from "react";
import { observer } from "mobx-react-lite";
import { GetStaticProps, NextPage } from "next";
import { useRouter } from "next/router";
import { Box, Button } from "@chakra-ui/react";
import { BannerCarousel, CardDistributor, OgTags, EmptyList, Section} from "~/components";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { homeOgTags } from "resources/PageOgTags";

interface IPageProps {
	homedata: api.HomeData;
}

export const getStaticProps: GetStaticProps<IPageProps> = async () => {
	try {
		const jsonHomeData = await api.getHomeData();
		const homedata = JSON.parse(JSON.stringify(jsonHomeData));

		return {
			props: {
				homedata,
			},
		};
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log("[Error]", error);
		return {
			notFound: true,
		};
	}
};

const HomePage: NextPage<IPageProps> = (props) => {
	const { homedata } = props;
	const string = strings.components;
	const route = useRouter();
	const onGoToDistributors = () => route.push("/distributors");

	return (
		<Box>
			<OgTags {...homeOgTags} />
			<BannerCarousel
				banners={homedata.banners}
			/>
			<Section
				title={string.distributors.title}
				width={{
					base: "80%",
				}}
				display="flex"
				gap={5}
				flexDirection="column"
				mx="auto"
				my={4}
				px={{
					base: 3,
					md: 0,
				}}
			>
				{!homedata.distributors.length ? (
					<EmptyList />
				) : (
					<>
						{homedata.distributors.map((item) => {
							return  <CardDistributor key={item.id} distributor={item} />;
						})}
					</>
				)}
				<Button
					borderColor="primary.500"
					color="primary.500"
					variant="outline"
					alignSelf="center"
					border="3px solid"
					mt={10}
					onClick={onGoToDistributors}
				>
					{string.distributors.seeMore}
				</Button>
			</Section >
		</Box>
	);
};

export default observer(HomePage);
